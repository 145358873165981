import getEnv from '@/util/env'
import EventBus from '@/util/EventBus.js'
import WhatsappEditor from '@/models/whatsapp-editor/WhatsappEditor.js'
import CountryService from '@/services/country.service'
import RoutesService from '@/services/whatsapp/routes.service'
import WhatsappTemplateService from '@/services/whatsapp-template.service.js'
import FixedMessagesService from '@/services/whatsapp/fixed-messages.js'
import { CurrentWhatsapp } from '@/classes/CurrentWhatsapp'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import WhatsappPreview from '@/components/Template/Whatsapp/preview/WhatsappPreview.vue'
import UrlsDialog from '@/views/Pages/Tools/Urls/UrlsDialog/UrlsDialog.vue'
import Info from '@/components/Info/Info.vue'
import { FixedMessageAuth } from '@/models/whatsapp/FixedMessageAuth.js'
import AiAssistantBodyWhatsapp from '@/components/AiAssistant/AiAssistantBodyWhatsapp.vue'
import AiAssistantHeaderWhatsapp from '@/components/AiAssistant/AiAssistantHeaderWhatsapp.vue'
import emojiRegex from 'emoji-regex'
import { WhatsappTemplateCategory } from '@/classes/WhatsappTemplateCategory'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import HeadboardOption from '../../../models/whatsapp-editor/HeadBoardOption'
import TypeOptionButton from '../../../models/whatsapp-editor/classes/TypeOptionButton'
import NewOptionButton from './options/NewOptionButton.vue'
import { EXTERNAL_LINK, EXTERNAL_PHONE, COPY_CODE, COUNT_EXTERNAL_LINK, COUNT_EXTERNAL_PHONE, COUNT_COPY_CODE } from '../../../models/whatsapp-editor/enums/CallToAction'
import { RESPONSE_AUTOMATIC, DISABLED_MARKETING, COUNT_DISABLE_MARKETING } from '../../../models/whatsapp-editor/enums/AutomaticResponse'
import { TYPE_OPTION_BUTTON } from '../../../models/whatsapp-editor/enums/TypeOptionButton'
import { CALL_TO_ACTION_TYPE } from '../../../models/whatsapp-editor/enums/CallToActionType'
import { RESPONSE_TYPE } from '../../../models/whatsapp-editor/enums/AutomaticResponseType'
import _ from 'lodash'
import multimediaService from '@/services/multimedia.service'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'
import HeadboardType from '../../../models/whatsapp-editor/HeadboardType'

export default {
  name: 'WhatsappEditor',
  props: {},
  components: {
    EmojiPickerInput,
    SelectCountry,
    WhatsappPreview,
    UrlsDialog,
    Info,
    AiAssistantBodyWhatsapp,
    AiAssistantHeaderWhatsapp,
    NewOptionButton,
    TextFormatting,
  },
  data: function () {
    return {
      whatsappEditor: new WhatsappEditor(),
      currentWhatsapp: new CurrentWhatsapp(),
      typeOptionButton: new TypeOptionButton(),
      countries: [],
      templateCategories: [],
      templateLanguages: [],
      chunkCountChange: false,
      showEmoji: true,
      urlsDialog: false,
      callToActionHelperModel: '',
      automaticResponsesHelperModel: '',
      automaticResponsesMarketingHelperModel: '',
      automaticResponsesOptions: [
        { value: 1, text: 'Respuesta normal', disabled: false },
        { value: 2, text: 'Desactivar marketing', disabled: true },
      ],
      disableHeadboardTypeSelection: false,
      fixedMessageAuth: new FixedMessageAuth(),
      whatsappWabas: [],
      exFieldsMessage: [],
      cont: 0,
      contHeader: 0,
      contUrl: 0,
      regex: null,
      templateVerifyDialogConfirm: false,
      redirectToHome: false,
      aiDrawerCtrl: {
        show: false,
      },
      aiDrawerHeaderCtrl: {
        show: false,
      },
      customFieldMinimum: true,
      callToActionType: CALL_TO_ACTION_TYPE,
      responseType: RESPONSE_TYPE,
      isSelectingFile: false,
      showDialogExternalButtonsSameText: false,
    }
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          routes.length
            ? this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })

    const templateId = this.$route.query.templateId
    const duplicateId = this.$route.query.duplicateId

    if (templateId) {
      this.getEditTemplate(templateId)
    } else if (duplicateId) {
      this.getDuplicateTemplate(duplicateId)
    } else {
      this.getRouteParams()
    }
    this.getAllCountries()
    this.getCategories()
    this.getLanguages()
    this.getCurrentUserRoutes()
  },
  mounted () {
    EventBus.$on('multimediaSelected', (url, type, fileId, nameTemplate, fileInfo) => {
      this.selectFile(url, fileInfo)
    })

    // change to interval check
    setTimeout(() => {
      this.$refs.mainMessageTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
    }, 5000)
  },
  unmounted () {
    EventBus.$off('multimediaSelected')
  },
  computed: {
    isButtonsOption () {
      return [this.whatsappEditor.headboardType.OPTION_TEXT, this.whatsappEditor.headboardType.OPTION_HEADER_TEXT].includes(this.whatsappEditor.selectedHeadboardType)
    },
     isOptionButtonCount () {
      if (this.whatsappEditor.callToActionResponses.length > 0 ||
        this.whatsappEditor.automaticResponses.length > 0
      ) {
        return true
      }

      return false
    },
    isValidateDisableMarketing () {
      let validate = true
      this.whatsappEditor.automaticResponses.forEach((automaticResponse) => {
        if (automaticResponse.type === RESPONSE_TYPE.DISABLE_MARKETING) {
          if (!automaticResponse.checkedDisableMarketing) {
            validate = false
          }
        }
      })

      return validate
    },
    translatedTemplateLanguages () {
      return this.templateLanguages.map(l => {
        l.name = this.$t(l.name)
        return l
      })
    },
    translatedHeadboardOptions () {
      return this.whatsappEditor.headboardOptions.map(o => {
        o.text = this.$t(o.text)
        return o
      })
    },
    areExampleFieldsValid () {
      if (this.isAuthentication) {
        return true
      }
      const headerValid = this.countHeaderValue > 0 ? this.whatsappEditor.customFieldsExampleValues.header !== '' : true
      let validMessageVars = true

      if (this.countVarsMessage > 0) {
        const emptyVars = this.whatsappEditor.customFieldsExampleValues.body.filter(bodyVar => { return bodyVar === '' })
        validMessageVars = emptyVars.length === 0
      }
      return headerValid && validMessageVars
    },
    countHeaderValue () {
      return this.contHeader ? this.contHeader : 0
    },
    countVarsMessage () {
      const size = this.whatsappEditor.message.split('{COL+}').length - 1
      for (let index = 0; index < size; index++) {
        if (this.whatsappEditor.customFieldsExampleValues.body[index] === undefined) {
          this.whatsappEditor.customFieldsExampleValues.body[index] = ''
        }
      }
      return size
    },
    messageHelper () {
      if (!this.fixedMessageAuth.authBodyMessage) return ''
      if (this.isAuthentication) {
        let text = this.fixedMessageAuth.authBodyMessage.replace('{{1}}', '{COL+}')
        if (this.whatsappEditor.HeadBoardTypeAuth.securityRecommendation) {
          text += this.fixedMessageAuth.authBodyRecommendation
        }
        this.whatsappEditor.message = text
      }
      return ''
    },
    isAuthentication () {
      return this.whatsappEditor.templateWhatsappCategoryId === WhatsappTemplateCategory.AUTHENTICATION
    },
    existsDisableMarketingOptionNoChecked () {
      const result = this.whatsappEditor.automaticResponses.filter((automaticResponse) => {
        console.log(automaticResponse)
        return automaticResponse.visible && automaticResponse.type === 2 && !automaticResponse.checkedDisableMarketing
      })
      return result.length
    },
    existsDisableMarketingOption () {
      const result = this.whatsappEditor.automaticResponses.filter((automaticResponse) => {
        return automaticResponse.type === 2 && automaticResponse.visible
      })
      return result.length > 0 && [this.whatsappEditor.headboardType.OPTION_TEXT, this.whatsappEditor.headboardType.OPTION_HEADER_TEXT].includes(this.whatsappEditor.selectedHeadboardType)
    },
    automaticResponsesTypes: function () {
      if (this.whatsappEditor.templateWhatsappCategoryId === 1) {
        return this.automaticResponsesOptions.filter((item) => item.value === 1)
      } else {
        return this.automaticResponsesOptions.map((item) => {
          item.text = this.$t(item.text).toString()
          return item
        })
      }
    },
    templateCategoriesTranslated: function () {
      return this.templateCategories.map((category) => {
        category.name = this.$t(category.name).toString()
        return category
      })
    },
    callToActionActiveActionsNumber: function () {
      if (!this.whatsappEditor) return 0
      return [this.whatsappEditor.callActionUrlActive, this.whatsappEditor.callActionPhoneActive].reduce((active, currentValue) => { return currentValue + (active ? 1 : 0) })
    },
    automaticResponsesActiveActionsNumber: function () {
      if (!this.whatsappEditor) return 0

      let sum = 0
      this.whatsappEditor.automaticResponses.forEach(response => {
        sum = sum + (response.visible ? 1 : 0)
      })
      return sum
    },
    categoryHelpMessage: function () {
      const cat = this.templateCategoriesTranslated?.find(c => c.id === this.whatsappEditor.templateWhatsappCategoryId)
      if (!cat) return ''
      switch (cat.id) {
        case 1:
          return this.$t('Envía confirmaciones de reserva, actualizaciones de entregas, recordatorios de citas y mucho más. Te recomendamos no usar palabras como promoción, oferta, descuento, así como mensajes de bienvenida, invitaciones, despedidas o recomendaciones, ya que podrían interpretarse como plantillas de marketing.')
        case 2:
          return this.$t('Envía notificaciones de campañas, promociones y ofertas; mensajes de bienvenida y despedida; actualizaciones, invitaciones o recomendaciones; o solicitudes para responder o información sobre tus productos, servicios o negocio.')
        case 3:
          return this.$t('Envía códigos de acceso de un solo uso para verificación de una transacción, recuperación de la cuenta, contraseñas.  No está permitido añadir URLs, encabezados, emojis en el contenido ni la personalización de plantillas.')
      }
    },
    isResponseAutomaticInvalid: function () {
      return (this.whatsappEditor.automaticResponses.filter((response) => response.visible && response.type === 1 && response.text === '').length > 0)
    },
    isValidateHeadboardInvalid: function () {
      const headBoardType = this.whatsappEditor.headboardType
      return [
        headBoardType.HEADER_TEXT,
        headBoardType.CALL_ACTION_HEADER_TEXT,
        headBoardType.RESPONSE_HEADER_TEXT,
      ].includes(this.whatsappEditor.selectedHeadboardType) &&
      this.whatsappEditor.selectedHeadboardOption !== HeadboardOption.options.LOCATION &&
      this.whatsappEditor.headboard === ''
    },
    isEditingTemplate: function () {
      return !!this.$route.query?.templateId && ![this.whatsappEditor.statusTemplate.DRAFT,
        this.whatsappEditor.statusTemplate.REJECTED,
        this.whatsappEditor.statusTemplate.INCORRECT_FORMAT,
      ].includes(this.whatsappEditor.templateWhatsappStatusId)
    },
    isEditingTemplateName: function () {
      return !!this.$route.query?.templateId
    },
    isEditingWabaTemplate: function () {
      return !!this.$route.query?.templateId &&
          this.whatsappEditor.templateWhatsappStatusId !== this.whatsappEditor.statusTemplate.DRAFT
    },
  },
  watch: {
    'currentWhatsapp.chunks': function (value) {
      this.chunkCountChange = true
      setTimeout(() => {
        this.chunkCountChange = false
      }, 500)
      // this.currentWhatsappIn.chunks = value
    },
    'whatsappEditor.templateWhatsappCategoryId': function (newValue, oldValue) {
      if (oldValue === WhatsappTemplateCategory.AUTHENTICATION) {
        this.whatsappEditor.footerPage = ''
        this.disableHeadboardTypeSelection = false
        this.selectWhatsappType(this.whatsappEditor.headboardType.TEXT)
        this.whatsappEditor.clearEditorFromAuth()
      }

      this.typeOptionButton = new TypeOptionButton()
      const disableMarketing = (new WhatsappEditor()).automaticResponseOptions.filter((option) => option.type === RESPONSE_TYPE.DISABLE_MARKETING)
      if (newValue === WhatsappTemplateCategory.UTILITY) {
        console.log(1)
        this.typeOptionButton.options = this.typeOptionButton.options.filter((option) => option.value !== 5)
        this.whatsappEditor.automaticResponseOptions = this.whatsappEditor.automaticResponseOptions.filter((option) => option.type !== RESPONSE_TYPE.DISABLE_MARKETING)
      } else {
        this.whatsappEditor.automaticResponseOptions.push(...disableMarketing)
        this.whatsappEditor.automaticResponseOptions[1].disabled = false
      }
    },
    'whatsappEditor.automaticResponses': function () {
      let exitDisabled = false
      this.whatsappEditor.automaticResponses.forEach((automaticResponse) => {
        if (automaticResponse.type === RESPONSE_TYPE.DISABLE_MARKETING) {
          exitDisabled = true
        }
      })

      if (this.whatsappEditor.automaticResponseOptions[1]) {
        this.whatsappEditor.automaticResponseOptions[1].disabled = exitDisabled
      }
    },
    'whatsappEditor.callToActionResponses': function () {
      let countLink = 0
      let countPhone = 0
      let countCode = 0
      this.whatsappEditor.callToActionOptions[0].disabled = false
      this.whatsappEditor.callToActionOptions[1].disabled = false
      this.whatsappEditor.callToActionOptions[2].disabled = false
      this.whatsappEditor.callToActionResponses.forEach((callToActionResponse) => {
        switch (callToActionResponse.type) {
          case CALL_TO_ACTION_TYPE.EXTERNAL_LINK:
            this.whatsappEditor.callToActionOptions[0].disabled = (++countLink >= COUNT_EXTERNAL_LINK)
            break
          case CALL_TO_ACTION_TYPE.EXTERNAL_PHONE:
            this.whatsappEditor.callToActionOptions[1].disabled = (++countPhone >= COUNT_EXTERNAL_PHONE)
            break
          case CALL_TO_ACTION_TYPE.COPY_CODE:
            this.whatsappEditor.callToActionOptions[2].disabled = (++countCode >= COUNT_COPY_CODE)
            break
        }
      })
    },
    'whatsappEditor.selectedHeadboardType': function (newValue, oldValue) {
      const responsesTypes = [this.whatsappEditor.headboardType.RESPONSE_TEXT, this.whatsappEditor.headboardType.RESPONSE_HEADER_TEXT]
      if (!responsesTypes.includes(newValue) && responsesTypes.includes(oldValue)) {
        this.whatsappEditor.footerPage = ''
      }
    },
    'whatsappEditor.HeadBoardTypeAuth.expireCodeMinutes': function () {
      this.whatsappEditor.footerPage = this.fixedMessageAuth.authFooterCodeExpiration.replace('{time}', this.whatsappEditor.HeadBoardTypeAuth.expireCodeMinutes)
    },
  },
  methods: {
    setLanguage () {
      const language = this.templateLanguages.find((language) => language.id === this.whatsappEditor.templateWhatsappLanguageId)
      if (language.footer_text) {
        DISABLED_MARKETING.textDisableFooter = language.footer_text
      }
      if (language.button_text) {
        DISABLED_MARKETING.textDisableMarketing = language.button_text
      }
    },
    toggleTypeOptionButton () {
      this.typeOptionButton.show = !this.typeOptionButton.show
    },
    addTypeOptionButton (option) {
      if (option === TYPE_OPTION_BUTTON[0].value) {
        this.addCallToAction(_.cloneDeep(EXTERNAL_LINK))
      }

      if (option === TYPE_OPTION_BUTTON[1].value) {
        this.addCallToAction(_.cloneDeep(EXTERNAL_PHONE))
      }

      if (option === TYPE_OPTION_BUTTON[2].value) {
        this.addCallToAction(_.cloneDeep(COPY_CODE))
      }

      if (option === TYPE_OPTION_BUTTON[3].value) {
        this.addAutomaticResponse(_.cloneDeep(RESPONSE_AUTOMATIC))
      }

      if (option === TYPE_OPTION_BUTTON[4].value) {
        this.addAutomaticResponse(_.cloneDeep(DISABLED_MARKETING))
        this.whatsappEditor.footerPage = DISABLED_MARKETING.textDisableFooter
      }
    },
    addCallToAction (callToAction) {
      if (!this.validateOptionResponse(callToAction)) {
        return false
      }

      if (callToAction.type === CALL_TO_ACTION_TYPE.COPY_CODE) {
        const languageIso = this.templateLanguages.find((language) => language.id === this.whatsappEditor.templateWhatsappLanguageId)
        callToAction.text = languageIso.copy_code_text
      }

      this.whatsappEditor.callToActionResponses.push(callToAction)
    },
    updatedCallToAction (index, option) {
      if (option === this.whatsappEditor.callToActionOptions[0].value) {
        this.whatsappEditor.callToActionResponses.splice(index, 1, _.cloneDeep(EXTERNAL_LINK))
      }

      if (option === this.whatsappEditor.callToActionOptions[1].value) {
        this.whatsappEditor.callToActionResponses.splice(index, 1, _.cloneDeep(EXTERNAL_PHONE))
      }

      if (option === this.whatsappEditor.callToActionOptions[2].value) {
        this.whatsappEditor.callToActionResponses.splice(index, 1, _.cloneDeep(COPY_CODE))
      }
    },
    removeCallToAction (index) {
      this.whatsappEditor.callToActionResponses.splice(index, 1)
    },
    addAutomaticResponse (automaticResponse) {
      if (!this.validateOptionResponse(automaticResponse)) {
        return false
      }
      this.whatsappEditor.automaticResponses.push(automaticResponse)
    },
    removeAutomaticResponse (index) {
      this.whatsappEditor.automaticResponses.splice(index, 1)
    },
    updatedAutomaticResponse (index, option) {
      if (option === this.whatsappEditor.automaticResponseOptions[0].value) {
        this.whatsappEditor.automaticResponses.splice(index, 1, _.cloneDeep(RESPONSE_AUTOMATIC))
      }

      if (option === this.whatsappEditor.automaticResponseOptions[1].value) {
        this.whatsappEditor.automaticResponses.splice(index, 1, _.cloneDeep(DISABLED_MARKETING))
      }
      this.changeLanguage()
    },
    validateOptionResponse (option) {
      const limit = 10
      const callToActionResponses = this.whatsappEditor.callToActionResponses
      const lengthCallToActionResponses = callToActionResponses.length
      const automaticResponses = this.whatsappEditor.automaticResponses
      const lengthAutomaticResponses = automaticResponses.length

      if (option.responseOption === 'call_to_action' && !this.validateOptionCallToActionResponses(callToActionResponses, option)) {
        return false
      }

      if (option.responseOption === 'automatic_response' && !this.validateOptionAutomaticResponses(automaticResponses, option)) {
        return false
      }

      if ((lengthCallToActionResponses + lengthAutomaticResponses) === limit) {
        EventBus.$emit('showAlert', 'danger', this.$t('El limite de opciones son 10 elementos'))
        return false
      }

      return true
    },
    /* eslint-disable max-depth */
    // eslint-disable-next-line complexity
    validateOptionCallToActionResponses (callToActionResponses, option) {
      let countExternalLink = 1
      let countExternalPhone = 1
      let countCopyCode = 1
      let success = true
      for (const callToActionResponse of callToActionResponses) {
        if (callToActionResponse.type === CALL_TO_ACTION_TYPE.EXTERNAL_LINK) {
          countExternalLink = countExternalLink + 1
          if (
            (option.type === CALL_TO_ACTION_TYPE.EXTERNAL_LINK) &&
              (countExternalLink > COUNT_EXTERNAL_LINK)
          ) {
            EventBus.$emit('showAlert', 'danger', this.$t('Solo puedes tener 2 botones de enlace externo en tu plantilla'))
            success = false
            break
          }
        }

        if (callToActionResponse.type === CALL_TO_ACTION_TYPE.EXTERNAL_PHONE) {
          countExternalPhone = countExternalPhone + 1
          if (
            (option.type === CALL_TO_ACTION_TYPE.EXTERNAL_PHONE) &&
              (countExternalPhone > COUNT_EXTERNAL_PHONE)
          ) {
            EventBus.$emit('showAlert', 'danger', this.$t('Solo puedes tener 1 botón de llamada telefónica en tu plantilla'))
            success = false
            break
          }
        }

        if (callToActionResponse.type === CALL_TO_ACTION_TYPE.COPY_CODE) {
          countCopyCode = countCopyCode + 1
          if (
            (option.type === CALL_TO_ACTION_TYPE.COPY_CODE) &&
              (countCopyCode > COUNT_COPY_CODE)
          ) {
            EventBus.$emit('showAlert', 'danger', this.$t('Solo puedes tener 1 botón de copiar código en tu plantilla'))
            success = false
            break
          }
        }
      }

      return success
    },
    /* eslint-disable max-depth */
    validateOptionAutomaticResponses (automaticResponses, option) {
      let countDisableMarketing = 1
      let success = true
      for (const automaticResponse of automaticResponses) {
        if (automaticResponse.type === RESPONSE_TYPE.DISABLE_MARKETING) {
          countDisableMarketing = countDisableMarketing + 1
          if (
            (option.type === RESPONSE_TYPE.DISABLE_MARKETING) &&
              (countDisableMarketing > COUNT_DISABLE_MARKETING)
          ) {
            EventBus.$emit('showAlert', 'danger', this.$t('Solo puedes tener 1 botón de desactivar marketing en tu plantilla'))
            success = false
            break
          }
        }
      }

      return success
    },
    clearCustomFieldExampleValuesMessage (indexCustomField) {
      let indexCol = 1
      const message = this.whatsappEditor.message
      for (let i = 0; i < message.length; i++) {
        if (message[i] === '{' && message.substr(i, 6) === '{COL+}') {
          // eslint-disable-next-line max-depth
          if (indexCustomField === indexCol) {
            const init = this.whatsappEditor.message.substr(0, i)
            const end = this.whatsappEditor.message = this.whatsappEditor.message.substr(i + 6, this.whatsappEditor.message.length - 1)
            this.whatsappEditor.message = init.concat(end)
          }
          indexCol = indexCol + 1
        }
      }
      this.whatsappEditor.customFieldsExampleValues.body.splice(indexCustomField - 1, 1)
    },
    clearCustomFieldExampleValuesHeader () {
      this.whatsappEditor.customFieldsExampleValues.header = ''
      this.whatsappEditor.headboard = this.whatsappEditor.headboard.replace('{COL+}', '')
      this.contHeader = 0
    },
    clearCustomFieldExampleValuesButton () {
      this.whatsappEditor.customFieldsExampleValues.button = ''
      this.whatsappEditor.callActionUrl = this.whatsappEditor.callActionUrl.replace('{COL+}', '')
      this.contUrl = 0
    },
    setHeaderAiIdea (idea) {
      this.whatsappEditor.headboard = idea
      this.$refs.headerInput.focus()
      this.$refs.headerInput.blur()
    },
    setAiIdea (idea) {
      this.whatsappEditor.message = idea
      this.parseWhatsapp()
    },
    openAiAssistant () {
      this.aiDrawerCtrl.show = true
    },
    changeHasResponse (index) {
      const response = []
      this.whatsappEditor.automaticResponses.map((automaticResponse) => {
        if (automaticResponse.visible && automaticResponse.type === 2) {
          response.push(automaticResponse)
        }
      })

      if (response.length > 0) {
        this.whatsappEditor.footerPage = response[response.length - 1].textDisableFooter
      } else {
        if (this.whatsappEditor.footerPage === this.whatsappEditor.automaticResponses[index].textDisableFooter) {
          this.whatsappEditor.footerPage = ''
        }
      }
    },
    prepareSelectedCategory () {
      if (this.whatsappEditor.templateWhatsappCategoryId !== 2) {
        this.automaticResponsesOptions = this.automaticResponsesOptions.map((item) => {
          if (item.value === 2) item.disabled = true
          return item
        })
        this.whatsappEditor.automaticResponses = this.whatsappEditor.automaticResponses.map((item) => {
          item.type = 1
          return item
        })
      } else {
        this.automaticResponsesOptions = this.automaticResponsesOptions.map((item) => {
          item.disabled = false
          return item
        })
      }

      if (this.isAuthentication) {
        this.selectWhatsappType(this.whatsappEditor.headboardType.CALL_ACTION_TEXT_AUTH)
        this.disableHeadboardTypeSelection = true
        this.setAuthMessageFromSelectedLangOrDefault()
      } else {
        this.disableHeadboardTypeSelection = false
      }
    },
    setAuthMessageFromSelectedLangOrDefault () {
      let isoCode = ''
      let interval = null
      if (Number.isInteger(this.whatsappEditor.templateWhatsappLanguageId)) {
        // eslint-disable-next-line no-unused-vars
        interval = setInterval(() => {
          if (this.templateLanguages.length > 0) {
            const foundLang = this.templateLanguages.find(lang => lang.id === this.whatsappEditor.templateWhatsappLanguageId)
            isoCode = foundLang.iso_code
            this.setAuthTypeMessagesTranslation(isoCode)
            clearInterval(interval)
            interval = null
          }
        }, 200)
      } else {
        const user = JSON.parse(localStorage.getItem('user'))
        isoCode = user.lang
        this.setAuthTypeMessagesTranslation(isoCode)
      }
    },
    showMultimedia (currentRootDirectory) {
      this.isSelectingFile = true
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    validateWhatsappHeaderType () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.whatsappEditor.selectedHeadboardType)
    },
    validateWhatsappCallActionType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.CALL_ACTION_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.CALL_ACTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappResponseType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.RESPONSE_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.RESPONSE_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    validateWhatsappOptionType () {
      if (
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.OPTION_TEXT ||
        this.whatsappEditor.selectedHeadboardType === this.whatsappEditor.headboardType.OPTION_HEADER_TEXT
      ) {
        return true
      }

      return false
    },
    changeHeadboardOption () {
      this.whatsappEditor.headboard = ''
      this.contHeader = 0
    },
    deleteHeadboard () {
      this.whatsappEditor.headboard = ''
    },
    selectWhatsappType (type) {
      if (this.disableHeadboardTypeSelection) return
      this.whatsappEditor.selectedHeadboardType = type
      setTimeout(() => {
        this.$refs.mainMessageTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
      })
    },
    parseWhatsapp () {
      console.log('parse called')
      this.updateCurrentWhatsapp(this.whatsappEditor.message)
    },
    validateShowEmoji (currentWhatsapp) {
      const chunks = currentWhatsapp.chunks
      const resto = (currentWhatsapp.maxCharacters - (currentWhatsapp.characters))

      if (chunks >= 2 && (resto === 1 || resto === 0)) {
        this.showEmoji = false
      } else {
        this.showEmoji = true
      }
    },
    updateCurrentWhatsapp (text) {
      text = this.validateBlankLines(text)
      this.$nextTick(function () { this.whatsappEditor.message = text })
      this.currentWhatsapp = CurrentWhatsapp.whatsappParserResultToCurrentWhatsapp(
        this.currentWhatsapp.encoding,
        text,
        this.currentWhatsapp.maxCharacters,
        this.currentWhatsapp.chunks,
      )
      if (text.length > this.currentWhatsapp.maxCharacters) {
        this.whatsappEditor.message = this.whatsappEditor.message.substr(0, this.currentWhatsapp.maxCharacters)
        this.currentWhatsapp.characters = this.currentWhatsapp.maxCharacters
      }
      this.validateShowEmoji(this.currentWhatsapp)
      this.valideCustomFieldMinimum(this.whatsappEditor.message)
    },
    valideCustomFieldMinimum (text) {
      const vars = text.trim().split('{COL+}').length - 1
      const countMinWords = vars + 3
      const words = text.trim().split('{COL+}').join(' ').trim().split(/\s+/).length

      if (this.isAuthentication) {
        this.customFieldMinimum = true
      } else {
        if (countMinWords <= words) {
          this.customFieldMinimum = true
        } else {
          this.customFieldMinimum = false
        }
      }
    },
    validateBlankLines (text) {
      var EOL = text.match(/\r\n/gm) ? '\r\n' : '\n'
      var regExp = new RegExp('(' + EOL + '){3,}', 'gm')
      text = text.replace(regExp, EOL + EOL)

      return text
    },
    trimMessage () {
      this.whatsappEditor.message = this.whatsappEditor.message.trim()
    },
    addEmojiToMessage (emoji) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.whatsappEditor.message = before + emoji + after

      this.$nextTick().then(() => {
        textarea.selectionStart = textarea.value.length
        textarea.click()
        this.parseWhatsapp()
      })
    },
    filterEmojis (str) {
      const regex = emojiRegex()

      return str.replace(regex, '')
    },
    addCustomFieldToMessage () {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      let col = '{COL+}'
      if (before.length && before.charAt(before.length - 1) !== ' ') {
        col = ' ' + col
      }
      if (after.length && after.charAt(0) !== ' ') {
        col += ' '
      }

      this.whatsappEditor.message = ''
      if (before.length) {
        this.whatsappEditor.message = before
      }
      this.whatsappEditor.message += col
      let cursorPosition = this.whatsappEditor.message.length
      if (after.length) {
        this.whatsappEditor.message += after
        cursorPosition--
      }

      this.$nextTick().then(() => {
        textarea.selectionStart = cursorPosition
        textarea.selectionEnd = cursorPosition
        textarea.click()
        this.parseWhatsapp()
      })
    },
    addCustomFieldToHeader () {
      const textarea = this.$refs.headerInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (this.contHeader < 1) {
        this.contHeader++
        if (pos === undefined) {
          pos = 0
        }

        const before = sentence.substr(0, pos)
        const after = sentence.substr(pos, len)

        this.whatsappEditor.headboard = before + '{COL+}' + after

        this.$nextTick().then(() => {
          textarea.selectionStart = textarea.value.length
          textarea.click()
          this.parseWhatsapp()
        })
      }
    },
    addCustomFieldToUrl (index) {
      const customField = this.whatsappEditor.callToActionResponses[index].customField

      if (!customField) {
        const text = this.whatsappEditor.callToActionResponses[index].url
        const lastChar = text.charAt(text.length - 1)
        const slash = lastChar !== '/' ? '/' : ''
        this.whatsappEditor.callToActionResponses[index].url = text + slash + '{COL+}'
        this.whatsappEditor.callToActionResponses[index].customField = true
        this.$nextTick().then(() => {
          this.parseWhatsapp()
        })
      }
    },
    checkCustomFiled (refInput, type) {
      const text = refInput.$refs.input
      const sentence = text.value
      const cf = sentence.split('{COL+}')
      if (cf.length === 1) {
        if (type === 'url') {
          this.contUrl = 0
        }
        if (type === 'header') {
          this.contHeader = 0
        }
      }
    },
    checkCustomFieldToUrl (index) {
      this.whatsappEditor.callToActionResponses[index].url = this.whatsappEditor.callToActionResponses[index].url.replace('{COL+}', '')
      this.whatsappEditor.callToActionResponses[index].customField = false
      this.whatsappEditor.callToActionResponses[index].example = ''
    },
    // eslint-disable-next-line complexity
    addFieldToMessage (item, type) {
      if (item !== null) {
        let fieldName = item
        if (type === 'field') {
          fieldName = '{' + item + '}'
        }
        if (type === 'form') {
          fieldName = '{FORM_' + item + '}'
        }
        if (type === 'attachment') {
          fieldName = '{ATTACHMENT_' + item + '}'
        }
        if (type === 'url') {
          fieldName = '{URL_' + item + '}'
        }

        this.insertFieldAtCursor(fieldName)
        this.parseWhatsapp()
        this.fields.currentFieldsMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, this.whatsappEditor.message)
        this.fields.currentUrlFieldsMessage = this.getCurrentFieldsArray(this.regex.urlRegExp, this.whatsappEditor.message)
      }
    },
    onInput () {
      this.whatsappEditor.headboard = this.filterEmojis(this.whatsappEditor.headboard)
      this.parseWhatsapp()
    },
    getCurrentFieldsArray (regExp, s) {
      let match = regExp.exec(s)
      const matches = []

      while (match != null) {
        match = match[0]
        matches.push(match.substr(1, (match.length - 2)))
        match = regExp.exec(s)
      }
      return matches
    },
    insertFieldAtCursor (myValue) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      if (myValue === '{UNSUB_URL}' && sentence.includes(myValue)) return
      if (!myValue.length) return

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.whatsappEditor.message = before + myValue + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + myValue.length
        textarea.click()
      })
    },
    getAllCountries () {
      CountryService
        .getAllCountries()
        .then(
          (response) => {
            this.countries = response.map((country) => {
              return {
                value: country.iso_code_2,
                text: `${country.name} +(${country.prefix})`,
                prefix: country.prefix,
              }
            })
          },
        )
    },
    getPrefixByIsoCode (index) {
      const that = this
      const filtered = this.countries.filter(country => country.value === that.whatsappEditor.callToActionResponses[index].phonePrefix)
      if (filtered.length > 0) {
        this.whatsappEditor.callToActionResponses[index].phonePrefix = filtered[0].prefix
      }
    },
    async selectFile (url, fileInfo) {
      if (!this.isSelectingFile) {
        return
      }
      this.isSelectingFile = false

      const [simpleType, tooBig, sizeTooBig] = HeadboardOption.fileType(fileInfo)

      if (simpleType === 'audio') {
        // Translate wav to mp3
        await multimediaService.getMp3FromAudio({
          filename: fileInfo.name + '.' + fileInfo.extension,
        })
          .then(response => {
            url = response.url
            fileInfo = response.fileInfo
          })
      }

      if (tooBig) {
        EventBus.$emit('showAlert', 'danger', this.$t('Has elegido un fichero demasiado grande. El tamaño máximo para este tipo de fichero es ') + sizeTooBig + 'MB.')
      } else {
        if (!simpleType?.length) {
          EventBus.$emit('showAlert', 'danger', this.$t('Este tipo de fichero no puede ser enviado a través de WhatsApp'))
        } else {
          this.whatsappEditor.headboard = {
            url: `${getEnv('VUE_APP_ASSETS_URL')}${url}`,
            mimeType: fileInfo.mimeType,
          }
        }
      }
    },
    getCategories () {
      WhatsappTemplateService.getCategories()
        .then(response => {
          this.templateCategories = response.data
        })
    },
    getLanguages () {
      WhatsappTemplateService.getLanguages()
        .then(response => {
          this.templateLanguages = response.data
          this.setLanguage()
        })
    },
    getRouteParams () {
      const templateId = this.$route.query.templateId
      if (this.whatsappEditor.id === null && !templateId) {
        this.whatsappEditor.name = this.$route.query.name ?? ''
        this.whatsappEditor.templateWhatsappCategoryId = parseInt(this.$route.query.category) ?? null
        this.whatsappEditor.templateWhatsappLanguageId = parseInt(this.$route.query.language) ?? null
        this.whatsappEditor.whatsappWabaId = this.$route.query.wabaId ?? null
        this.prepareSelectedCategory()
      }
    },
    getEditTemplate (templateId) {
      WhatsappTemplateService.getTemplate(templateId)
        .then((response) => {
          this.whatsappEditor.load(response.data)
          this.contUrl = this.whatsappEditor.callActionUrl
            ? this.whatsappEditor.callActionUrl.split('{COL+}').length - 1
            : 0
          this.contHeader = this.whatsappEditor.headboard
            ? JSON.stringify(this.whatsappEditor.headboard)?.split('{COL+}').length - 1
            : 0
          this.prepareSelectedCategory()
        })
    },
    getDuplicateTemplate (templateId) {
      WhatsappTemplateService.getTemplate(templateId)
        .then((response) => {
          this.whatsappEditor.load(response.data)
          this.contUrl = this.whatsappEditor.callActionUrl
            ? this.whatsappEditor.callActionUrl.split('{COL+}').length - 1
            : 0
          this.contHeader = this.whatsappEditor.headboard
            ? JSON.stringify(this.whatsappEditor.headboard)?.split('{COL+}').length - 1
            : 0
          this.whatsappEditor.id = null
          this.whatsappEditor.name += '_' + this.$t('copia')
          this.prepareSelectedCategory()
        })
    },
    sendTemplateToVerify () {
      this.redirectToHome = true
      this.templateVerifyDialogConfirm = false
      this.whatsappEditor.templateWhatsappStatusId = this.whatsappEditor.statusTemplate.PENDING
      this.saveTemplate()
    },
    saveDraftTemplate () {
      this.whatsappEditor.templateWhatsappStatusId = this.whatsappEditor.statusTemplate.DRAFT
      this.saveTemplate()
    },
    buttonsWithSameText () {
      let buttonsCount = 0
      let buttonsText = []
      if (this.whatsappEditor.callToActionResponses.length > 0) {
        buttonsCount += this.whatsappEditor.callToActionResponses.length
        buttonsText = [
          ...buttonsText,
          ...this.whatsappEditor.callToActionResponses.map(b => b.text),
        ]
      }

      if (this.whatsappEditor.automaticResponses.length > 0) {
        buttonsCount += this.whatsappEditor.automaticResponses.length
        buttonsText = [
          ...buttonsText,
          ...this.whatsappEditor.automaticResponses.map(b => {
            if (b.checkedDisableMarketing) {
              return b.textDisableMarketing
            } else {
              return b.text
            }
          }),
        ]
      }

      return (new Set(buttonsText)).size !== buttonsCount
    },
    checkTemplateBeforeValidating () {
      if (this.buttonsWithSameText()) {
        this.showDialogExternalButtonsSameText = true
        return
      }
      this.templateVerifyDialogConfirm = true
    },
    saveTemplate () {
      this.fixBodyMessageExampleVars()
      if (this.whatsappEditor.id === null) {
        this.createTemplate()
      } else {
        this.updateTemplate()
      }
    },
    fixBodyMessageExampleVars () {
      this.whatsappEditor.customFieldsExampleValues.body = this.whatsappEditor.customFieldsExampleValues.body.slice(0, this.countVarsMessage)
      this.whatsappEditor.customFieldsExampleValues.header = this.contHeader === 0 ? '' : this.whatsappEditor.customFieldsExampleValues.header
      this.whatsappEditor.customFieldsExampleValues.button = []
      this.whatsappEditor.callToActionResponses.forEach((response) => {
        const example = response.example?.length ? response.example : null
        this.whatsappEditor.customFieldsExampleValues.button.push(example)
      })
    },
    createTemplate () {
      const data = this.whatsappEditor.getWhatsappEditor()
      WhatsappTemplateService.create(data)
      .then(response => {
        this.whatsappEditor.id = response.data.id
        EventBus.$emit('showAlert', 'success', this.$t('Plantilla creada con éxito'))
        this.checkRedirectAfterSave()
      })
      .catch((error) => {
        if (error.response.status === 422) {
          EventBus.$emit('showAlert', 'danger', this.$t('No es posible crear una plantilla con el nombre, categoría e idioma duplicado'))
        } else {
          EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al crear la plantilla'))
        }
      })
    },
    updateTemplate () {
      const data = this.whatsappEditor.getWhatsappEditorForSave()
      WhatsappTemplateService.save(data)
      .then((response) => {
        EventBus.$emit('showAlert', 'success', this.$t('Plantilla actualizada con éxito'))
        this.checkRedirectAfterSave()
      })
      .catch(() => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al actualizar la plantilla'))
      })
    },
    checkRedirectAfterSave () {
      if (this.redirectToHome) {
        this.$router.push({ name: 'HomePage' })
      }
    },
    formatSnakeCase () {
      const string = this.whatsappEditor.name.toLowerCase()
          .replace(/\W+/g, ' ')
          .split(/ |\B(?=[A-Z])/)
          .join('_')
      this.whatsappEditor.name = string
    },
    changeLanguage () {
      this.whatsappEditor.automaticResponses.forEach((response, index) => {
        if (response.type === 2) {
          this.changeAutomaticResponseType(index)
        }
      })

      this.whatsappEditor.callToActionOptions.forEach((response, index) => {
        if (response.type === 3) {
          this.changeCallToActionResponseType(response.type)
        }
      })

      if (this.whatsappEditor.templateWhatsappCategoryId === WhatsappTemplateCategory.AUTHENTICATION) {
        this.setAuthMessageFromSelectedLangOrDefault()
      }
    },
    setAuthTypeMessagesTranslation (isoCode) {
      FixedMessagesService.getByIsoCode({ isoCode: isoCode }).then((response) => {
        this.fixedMessageAuth.loadData(response)
        this.whatsappEditor.HeadBoardTypeAuth.autocompleteText = this.fixedMessageAuth.authButtonAutofill
        this.whatsappEditor.HeadBoardTypeAuth.copyCodeText = this.fixedMessageAuth.authButtonCodeCopy
        this.whatsappEditor.footerPage = this.fixedMessageAuth.authFooterCodeExpiration.replace('{time}', this.whatsappEditor.HeadBoardTypeAuth.expireCodeMinutes)
      })
    },
    setNormalResponseOnTwiceDisabledMarketing (index) {
      if (this.whatsappEditor.automaticResponses[index].type === 2) {
        this.whatsappEditor.automaticResponses.filter((element, indexIn) => indexIn !== index && element.type === 2).forEach(autoAnswer => {
          autoAnswer.type = 1
        })
      }
    },
    resetAutomaticResponseWithDisableMarketing (index) {
      this.whatsappEditor.automaticResponses.map((element, indexIn) => {
        if (indexIn !== index && element.type === 1) {
          this.whatsappEditor.automaticResponses[indexIn].textDisableFooter = ''
          this.whatsappEditor.automaticResponses[indexIn].textDisableMarketing = ''
          this.whatsappEditor.automaticResponses[indexIn].checkedDisableMarketing = false
        }
      })
    },
    changeCallToActionResponseType (type) {
      if (type === CALL_TO_ACTION_TYPE.COPY_CODE) {
        const languageIso = this.templateLanguages.find((language) => language.id === this.whatsappEditor.templateWhatsappLanguageId)
        this.whatsappEditor.callToActionResponses.forEach((callToActionResponse, index) => {
          if (callToActionResponse.type === CALL_TO_ACTION_TYPE.COPY_CODE) {
            this.whatsappEditor.callToActionResponses[index].text = languageIso.copy_code_text
          }
        })
      }
    },
    changeAutomaticResponseType (index) {
      this.setNormalResponseOnTwiceDisabledMarketing(index)
      this.resetAutomaticResponseWithDisableMarketing(index)
      if (this.whatsappEditor.automaticResponses[index].type === 1) {
        this.whatsappEditor.footerPage = ''
      }

      if (this.whatsappEditor.automaticResponses[index].type === 2) {
        if (!this.whatsappEditor.templateWhatsappLanguageId) {
          this.whatsappEditor.footerPage = this.whatsappEditor.automaticResponses[index].textDisableFooter
        } else {
          this.templateLanguages.forEach((language) => {
            if (language.id === this.whatsappEditor.templateWhatsappLanguageId) {
              if (language.footer_text) {
                this.whatsappEditor.footerPage = language.footer_text
                this.whatsappEditor.automaticResponses[index].textDisableFooter = language.footer_text
              }
              if (language.button_text) {
                this.whatsappEditor.automaticResponses[index].textDisableMarketing = language.button_text
              }
            }
          })
        }
      }
    },
    getCurrentUserRoutes () {
      RoutesService.getCurrentUserAllRoutes()
      .then((routes) => {
        routes.forEach(route => {
          this.whatsappWabas.push({
            id: route.waba_id,
            name: route.display_name + ' - ' + route.waba_id,
          })
        })
      })
    },
  },
}
